import React from "react"
import { CheckCircleIcon } from "@heroicons/react/outline"

interface Props {
  items: string[] | React.ReactNode[]
  className?: string
  pretty?: boolean
  textColor?: string
}

const Ul: React.FC<Props> = ({
  items,
  className,
  pretty = false,
  textColor = "text-gray-600",
}) => {
  return (
    <ul
      className={`mt-3 space-y-3 ${
        !pretty ? "list-disc pl-5" : ""
      } ${textColor} ${className ? className : ""} `}
    >
      {items.map((item, i) => (
        <li key={i} className={pretty ? "flex" : undefined}>
          {pretty ? (
            <CheckCircleIcon className="h-4 w-4 inline-block mr-3 text-primary mt-1 flex-shrink-0" />
          ) : null}
          <div>{item}</div>
        </li>
      ))}
    </ul>
  )
}

export default Ul
