import { StaticImage } from "gatsby-plugin-image"
import React from "react"

/* This example requires Tailwind CSS v2.0+ */
export default function Partner() {
  return (
    <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
      <div className="flex items-center justify-center col-span-1 px-8 py-8 border-2 border-gray-50">
        <a
          href="https://lautenschlager.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../media/partner/seo-agentur-stuttgart-partner-lautenschlager.svg"
            alt="SEO Agentur Stuttgart Partner Lautenschlager Marketing & Entwicklung"
            title="SEO Agentur Stuttgart Partner Lautenschlager Marketing & Entwicklung"
            style={{ maxWidth: 200 }}
          />
        </a>
      </div>
      <div className="flex items-center justify-center col-span-1 px-8 py-8 border-2 border-gray-50">
        <a
          href="https://www.peterscheerer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../media/partner/seo-agentur-stuttgart-partner-peter-scheerer.png"
            alt="SEO Agentur Stuttgart Partner Jimdo Experte"
            title="SEO Agentur Stuttgart Partner Jimdo Experte"
            style={{ maxWidth: 200 }}
          />
        </a>
      </div>
      <div className="flex items-center justify-center col-span-1 px-8 py-8 border-2 border-gray-50">
        <a
          href="https://www.gebaeudeservice-breitinger.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../media/partner/breitinger.jpg"
            alt="SEO Agentur Stuttgart Partner Breitinger Gebäudeservice"
            title="SEO Agentur Stuttgart Partner Breitinger Gebäudeservice"
            style={{ maxWidth: 200 }}
          />
        </a>
      </div>
    </div>
  )
}
