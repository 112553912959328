import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../common/Button"
import H2 from "../typography/H2"
import P from "../typography/P"

export default function Garantie() {
  return (
    <div className="lg:grid lg:grid-cols-12">
      <div className="relative z-10 hidden md:block lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
        <div
          className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
          aria-hidden="true"
        />
        <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
          <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
            <StaticImage
              src="../../media/seo-agentur-stuttgart-garantie.jpg"
              alt="SEO Agentur Stuttgart Garantie"
              title="SEO Agentur Stuttgart Garantie"
              className="object-cover object-center shadow-2xl rounded-3xl"
            />
          </div>
        </div>
      </div>

      <div className="relative bg-gray-50 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
        <div
          className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block"
          aria-hidden="true"
        >
          <svg
            className="absolute transform bottom-full left-full translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
          <svg
            className="absolute transform top-full -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-indigo-500"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
        <div className="relative max-w-md px-4 py-12 mx-auto space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:col-start-4 lg:col-span-6">
          <H2 display className="mb-8">
            Garantie – SEO Agentur Stuttgart
          </H2>
          <P>
            Die SEO PREMIUM AGENTUR Stuttgart erfüllt{" "}
            <strong>PREMIUM-Qualitäts-Standards</strong>. Aufgrund unserer
            jahrelangen Erfahrung mit unterschiedlichsten Branchen und Keywords
            können wir, bevor wir ein Projekt annehmen, durch modernste
            Analyse-Tools feststellen, ob es durchführbar ist. Alle Keywords
            werden auf Konkurrenz & Suchvolumen geprüft, sodass wir bereits bei
            der Auftragsannahme für Deine „Google-Seite-1-Platzierung“ eine{" "}
            <strong>Geld-zurück-Garantie*</strong> abgeben können.
          </P>
          <P>
            Der Inhaber der Agentur wurde am 8.6.2021 durch den{" "}
            <Link
              to="/blog/seo-fachkraeftezertifikat-bvdw/"
              className="text-blue-500"
            >
              Bundesverband
            </Link>{" "}
            Digitale Wirtschaft <strong>(BVDW)</strong> e. V. geprüft und mit
            dem Fachkräftezertifikat ausgezeichnet.
          </P>
          <P>
            <span className="text-xs">
              *die Geldzurückgarantie ist erfüllt, wenn das oder die
              vereinbarte/n Keyword/s nicht innerhalb von 6 Monaten bei Google
              auf der ersten Seite gerankt wurden. Erfahrungsgemäß ranken die
              Keywords innerhalb von 6-12 Wochen nach Fertigstellung der
              Onpage-Optimierung.
            </span>
          </P>
          <Button to="/anfragen/" text="DER KOSTENLOSE SEO-CHECK" />
        </div>
      </div>
    </div>
  )
}
