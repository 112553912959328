import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SchemaLocalBusiness = ({ title, description }) => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            siteUrl
            contact {
              mail
            }
          }
        }

        logo: file(relativePath: { eq: "seo-agentur-stuttgart-logo.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        profile: file(
          relativePath: { eq: "seo-agentur-stuttgart-oliver.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }

        allReviews {
          nodes {
            author
            dateLocale: date(formatString: "DD.MM.YYYY")
            date
            id
            rating
            text
          }
          totalCount
        }
      }
    `
  )

  let aggregateRating = 0
  data.allReviews.nodes.forEach(review => {
    aggregateRating = aggregateRating + review.rating
  })
  aggregateRating = aggregateRating / data.allReviews.totalCount.toFixed(2)

  const ldJson = {
    "@context": "http://schema.org",
    "@type": "Product",
    "@id": data.site.siteMetadata.siteUrl,
    name: title,
    image: `${data.site.siteMetadata.siteUrl}${data.profile.childImageSharp.gatsbyImageData.images.fallback.src}`,
    description,
    brand: data.site.siteMetadata.title,
    aggregateRating: {
      "@type": "aggregateRating",
      worstRating: 1,
      bestRating: 5,
      reviewCount: data.allReviews.totalCount.toString(),
      ratingCount: data.allReviews.totalCount.toString(),
      ratingValue: aggregateRating.toString(),
    },
    review: [],
  }

  const priceldJson = {
    offers: {
      "@type": "AggregateOffer",
      url: data.site.siteMetadata.siteUrl,
      priceCurrency: "EUR",
      lowPrice: 100,
      highPrice: 10000,
    },
  }

  data.allReviews.nodes.forEach(review => {
    const schemaData = {
      "@type": "Review",
      name: review.author ? review.author : "Anonym",
      reviewBody: review.text,
      reviewRating: {
        "@type": "Rating",
        ratingValue: review.rating.toString(),
        bestRating: 5,
        worstRating: 1,
      },
      datePublished: review.date,
      author: {
        "@type": "Person",
        name: review.author ? review.author : "Anonym",
      },
    }
    ldJson.review.push(schemaData)
  })

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(Object.assign(ldJson, priceldJson))}
      </script>
    </Helmet>
  )
}

export default SchemaLocalBusiness
