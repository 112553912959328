import React from "react"
import H2 from "../typography/H2"

const faqs = [
  {
    question: "Was passiert, nachdem ich mich eingetragen habe?",
    answer:
      "Du erhältst einen Anruf oder Link für eine Terminvereinbarung, um das Ergebnis Deiner Webseitenanalyse zu besprechen.",
  },
  {
    question: "Wie gehts nach diesem Gespräch weiter?",
    answer:
      "Die Möglichkeiten und weitere Schritte einer Zusammenarbeit werden besprochen, danach das für dich passende Angebot ermittelt.",
  },
  {
    question: "Wie ist die weitere Vorgehensweise bei einer Zusammenarbeit?",
    answer:
      "Wir erstellen alle notwendigen Analysen und setzen diese in einem Zoom-Call gemeinsam mit Dir direkt auf Deiner Webseite um. In der Regel sind mehrere Zoom-Calls notwendig, bis die Onpage-Arbeiten erledigt sind. Je nach Auftrag erhältst Du Hausaufgaben, die von Dir selbst umzusetzen sind. Backlinks zu Deiner Seite werden in den ersten 6-12 Wochen gesetzt.",
  },
  {
    question: "Warum kann die SEO PREMIUM AGENTUR eine Garantie geben?",
    answer:
      "Wie unser Name besagt, wollten wir ein Premium-Produkt anbieten, das es so noch nicht gab. Die Garantie gewähren wir, weil wir mit unserer jahrelangen Kompetenz, Praxiserfahrung und modernster Software die Suchmaschinenoptimierung On- & Off-Page unterstützen. Wir behalten uns vor, Kundenanfragen in bestimmten Fällen abzulehnen.",
  },
  {
    question:
      "Was ist wichtig bei einer Zusammenarbeit mit der SEO PREMIUM AGENTUR?",
    answer:
      "Bei unserer hohen Qualität sind Ressourcen begrenzt vorhanden, weshalb wir nur eine begrenzte Anzahl an Neukunden im Monat annehmen können.",
  },
]

export default function Faq() {
  return (
    <>
      <H2 display>Häufig gestellte Fragen</H2>
      <div className="pt-10 mt-6">
        <dl className="grid gap-8 md:grid-cols-2">
          {faqs.map((faq, i) => (
            <div key={i}>
              <dt className="text-lg font-medium leading-6 text-gray-900">
                {faq.question}
              </dt>
              <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
            </div>
          ))}
        </dl>
      </div>
    </>
  )
}
