import { StarIcon } from "@heroicons/react/solid"
import { graphql, useStaticQuery } from "gatsby"
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import React from "react"

function ReviewSlider({ className }) {
  const data = useStaticQuery(graphql`
    {
      allReviews {
        nodes {
          date
          rating
          text
          author
          id
        }
        totalCount
      }
    }
  `)

  return (
    <CarouselProvider
      visibleSlides={1}
      totalSlides={data.allReviews.totalCount}
      step={1}
      naturalSlideWidth={320}
      naturalSlideHeight={100}
      infinite={true}
      isIntrinsicHeight={true}
      className={`${className ? className : ""}`}
    >
      <Slider>
        {data.allReviews.nodes.reverse().map((review, i) => {
          let stars = []
          for (let i = 1; i <= review.rating; i++) {
            stars.push(
              <StarIcon
                className="inline-block w-4 h-4 text-yellow-300"
                key={i}
              />
            )
          }
          return (
            <Slide index={i} key={i}>
              <div className="relative p-3 cursor-grab bg-pattern">
                <figure className="p-3 bg-white">
                  <div className="mb-3">{stars}</div>
                  <blockquote className="mb-3">
                    {review.text.split("").length > 300
                      ? review.text.slice(0, 300) + "..."
                      : review.text}
                  </blockquote>
                  <figcaption className="text-right">
                    {review.date}
                    {" • "}
                    <cite className="font-bold">
                      {review.author ? review.author : "Anonym"}{" "}
                    </cite>
                  </figcaption>
                </figure>
              </div>
            </Slide>
          )
        })}
      </Slider>
      <div className="flex justify-end mb-5 space-x-3">
        <ButtonBack className="inline-flex items-center justify-center px-5 py-2 text-white transition-colors duration-300 bg-black border border-transparent hover:bg-gray-800 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-black">
          Zurück
        </ButtonBack>
        <ButtonNext className="inline-flex items-center justify-center px-5 py-2 text-white transition-colors duration-300 bg-black border border-transparent hover:bg-gray-800 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-black">
          Nächste
        </ButtonNext>
      </div>
      <div>
        <p className="text-right text-gray-700">
          <a
            href="https://www.webwiki.de/seo-premium-agentur.de"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Zu allen Bewertungen
          </a>
        </p>
      </div>
    </CarouselProvider>
  )
}

export default ReviewSlider
